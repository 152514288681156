<template>
    <div class="lawfirm">
        <van-nav-bar left-arrow @click-left="onClickLeft" class="nav-style" title="调解系统服务费" fixed placeholder safe-area-inset-top />
        <ShowTotal :dataJson='dataJson' />
        <van-tabs class="tab-top" v-model="active">
            <van-tab title="服务明细"></van-tab>
            <van-tab title="结算记录"></van-tab>
        </van-tabs>
        
        <ItemServiceList :check='active' :list='list' :imgName='imgName' @itemClick="linkDetail"></ItemServiceList>
    </div>
</template>

<script>
import { button, Toast ,NavBar , Tab, Tabs } from 'vant';
import ShowTotal from '@/components/ShowTotal'
import ItemServiceList from '@/components/ItemServiceList'
export default {
    name:"mediate",
    data(){
        return{
            dataJson:{
                title:'结算总额',
                total:'0.00',
                frequencyName:"共服务案件：",
                frequencyVal:"0",
                numName:"共结算案件：",
                numVal:"0",
            },
            active:0,
            imgName:require("../../assets/mediate.png"),
            list:[]
        }
    },
    watch: {
        active(val) {
            if(val == 0) this.getService()
            else this.getDept()
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        linkDetail(deptCode){
            if(this.active == 0){
                this.$router.push({path:'/mediate/serviceDetail',query:{deptCode:deptCode}})
            }else{
                this.$router.push({path:'/mediate/detail',query:{deptCode:deptCode}})
            }
        },
        getTotal(){
            this.$get(`/finance/api/fee/staticsTotal`,{feeType:"30060005"}).then(res => {
                if (res.code === '200') {
                    this.dataJson.total = res.data.settlementMoney
                    this.dataJson.frequencyVal = res.data.caseNumber
                    this.dataJson.numVal = res.data.settlementNumber
                    this.getService()
                }
            })
        },
        getService(){
            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
            });
            this.$get(`/finance/api/fee/statisticCaseNumber`,{feeType:"30060005"}).then(res => {
                if (res.code === '200') {
                    this.list = res.data
                    Toast.clear();
                }
            })
        },
        getDept(){
            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
            });
            this.$get(`/finance/api/fee/mediate/getDeptCaseFeeList`).then(res => {
                if (res.code === '200') {
                    this.list = res.data.records
                    Toast.clear();
                }
            })
        }
    },
    mounted() {
        this.getTotal()
    },
    components:{
        [Toast.name]: Toast,
        [button.name]: button,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [NavBar.name]: NavBar,
        ShowTotal,
        ItemServiceList
    }
}
</script>

<style lang="scss" scoped>
    .lawfirm{
        background: #F4F4F4;
        height: 100vh;
    }
</style>